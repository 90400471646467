<template>
  <div
    class="block"
    :class="[`block--${blockType}`, ( blockIsFocused ? 'block--focused' : '' )]"
    :tabindex="indexOfTheBlock"
    :data-index="indexOfTheBlock"
    @keydown.enter="enterKey"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
    data-type="block"
  >
    <div
      :class="[
        'block__content',
        {'block__content--highlight' : blockContentHighlight(indexOfTheBlock) }
      ]"
    >
      <FormatToolbar
        v-if="!disabled && formatToolbar.length"
        v-show="blockIsFocused"
        :block-type="blockType"
        :index="indexOfTheBlock"
        :formatOptions="formatToolbar"
        :editor="editor"
      />
      <slot></slot>
    </div>

    <OptionsToolbar
      v-if="!disabled"
      :block-type="blockType"
      :click-handler-for-edit-btn="clickHandlerForEditBtn"
      :show-edit-btn="showEditBtn"
      :show-toolbar="blockIsFocused"
    />
  </div>
</template>

<script>
import FormatToolbar from '@/components/nmhEditor/toolbars/FormatToolbar'
import OptionsToolbar from '@/components/nmhEditor/toolbars/OptionsToolbar'
import { mapState } from 'vuex'
export default {
  name: 'Block',
  components: {
    FormatToolbar,
    OptionsToolbar
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    enterKey: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    /**
     * Index of the current block
     */
    indexOfTheBlock: {
      type: Number,
      required: true
    },
    /**
     * Enter handler will be enabled in this block
     */
    specialBehaviorAfterPressingEnter: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Available formatting
     * @values bold, code, headings, italic, link, strike, htmlIdAttr
     */
    formatToolbar: {
      type: Array,
      required: true
    },
    /**
     * Editor instance for the format-toolbar
     */
    editor: {
      type: null,
      required: false
    },
    /**
     * Edit button of the block
     */
    showEditBtn: {
      type: [Boolean, Number],
      required: false
    },
    /**
     * Click event function
     */
    clickHandlerForEditBtn: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      blockIsFocused: false
    }
  },
  computed: {
    ...mapState({
      deleteBlockModal: state => state.nmhEditorStore.modals.deleteBlock.isVisible,
      deleteAllBlocks: state => state.nmhEditorStore.modals.deleteBlock.deleteAllBlocks,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock,
      linkModal: state => state.nmhEditorStore.modals.link.isVisible,
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    })
  },
  methods: {
    blockContentHighlight (indexOfTheBlock) {
      return (this.lastClickedBlock.index === indexOfTheBlock && this.deleteBlockModal && this.deleteAllBlocks === false) ||
        (this.lastClickedBlock.index === indexOfTheBlock && this.linkModal)
    },
    mouseOver () {
      this.blockIsFocused = true
      this.$store.commit('nmhEditorStore/SET_ITEMS_MENU_HORIZONTAL_NOW_HOVERED', this.blockType)
    },
    mouseOut () {
      this.blockIsFocused = false
      this.$store.commit('nmhEditorStore/SET_ITEMS_MENU_HORIZONTAL_NOW_HOVERED', '')
    }
  }
}
</script>

<style scoped lang="scss">
  .block {
    @include font(400 16px "Roboto");
    @include relative(_);
    @include radius(6px);
    @include grid-gap(5px);
    display: grid;
    color: #26272a;
    @include bp(11) {
      @include grid-gap(10px);
      grid-template-columns: auto min-content;
    }
    &--pageBreakBlock {
      border-top: solid 1px #d1dbe4;
      border-bottom: solid 1px #d1dbe4;
      border-left: dashed 1px #d1dbe4;
      border-right: dashed 1px #d1dbe4;
      border-radius: 0 !important;
      margin-left: rem(-17px);
      margin-right: rem(-17px);
      padding: rem(12px) rem(6px) rem(3px) rem(14px);
      .article-view-blocks & {
        background: #F2F4F8FF;
      }
      .article-edit-view-blocks & {
        background: #F5F6FAFF;
      }
    }
    &__content {
      @include padding(17px 15px);
      @include radius(6px);
      position: relative;
      border: solid 1px #d1dbe4;
      .block--pageBreakBlock &,
      .block--paidContentBreakBlock & {
        @include padding(17px 0);
        border: none;
      }
      .block--relatedArticleBlock & {
        background: #e9ecef;
      }
      .block--sourceBlock & {
        background: #F5F5F9;
      }
      .block--embeddedApplicationBlock & {
        background: #e9ecef;
      }
      .nmh-editor--dragging & {
        box-shadow: 0 0 12px 1px rgba(101, 153, 254, .5);
      }
      &--highlight {
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, .2);
      }
    }
    /deep/ .ProseMirror {
      outline: none;
      .is-empty {
        &::before {
          @include size(_ 0);
          content: attr(data-placeholder);
          float: left;
          color: #8a96ac;
          pointer-events: none;
          font-style: italic;
        }
      }
      h2, h3, h4, h5, h6 {
        @include margin(_ _ 0);
        a {
          color: #6599fe;
          text-decoration: underline;
        }
      }
      h2 {
        @include font(400 26px "Roboto");
      }
      h3 {
        @include font(400 23px "Roboto");
      }
      h4 {
        @include font(400 19px "Roboto");
      }
      h5 {
        @include font(400 17px "Roboto");
      }
      h6 {
        @include font(400 16px "Roboto");
      }
      p {
        @include margin(_ _ 0);
        line-height: 25px;
        strong {
          font-weight: 700;
          color: #000;
        }
        a {
          color: #387cff;
          text-decoration: none;
          strong {
            font-weight: 700;
            color: #387cff;
          }
        }
        code {
          @include font(400 14px 26px "Roboto");
          @include padding(_ 8px);
          @include radius(5px);
          background: rgba(0,0,0,.05);
          color: #f92d2d;
          border: 1px solid rgba(0,0,0,.09);
          white-space: pre-wrap;
        }
        em {
          font-style: italic;
        }
        u {
          text-decoration: underline;
        }
        s {
          text-decoration: line-through;
        }
      }
      pre {
        @include margin(0);
        box-shadow: none;
        code {
          @include font(400 13px 18px "Fira Code");
          display: block;
          color: #fff;
          font-family: inherit;
          word-break: break-word;
        }
      }
      ol, ul {
        @include grid-gap(6px);
        @include margin(0);
        @include padding(0);
        display: grid;
        padding-inline-start: rem(20px);
      }
      ol {
        @include padding(_ _ _ 18px);
        counter-reset: list;
      }
      ol li {
        @include relative(_);
        margin: 0;
        &::marker {
          color: #6599fe;
          font-size: 1em;
        }
        > p {
          display: inline;
        }
      }
      ul li {
        @include relative(_);
        &::marker {
          color: #6599fe;
          font-size: 1em;
        }
        > p {
          display: inline;
        }
      }
      table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td,
        th {
          min-width: 1em;
          border: 2px solid #ced4da;
          padding: 3px 5px;
          vertical-align: top;
          box-sizing: border-box;
          position: relative;

          > * {
            margin-bottom: 0;
          }
        }

        th {
          text-align: left;
          background-color: #f1f3f5;
        }

        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: "";
          left: 0; right: 0; top: 0; bottom: 0;
          background: rgba(200, 200, 255, 0.4);
          pointer-events: none;
        }

        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: -2px;
          width: 4px;
          background-color: #adf;
          pointer-events: none;
        }

        p {
          margin: 0;
        }
      }
    }
  }
</style>

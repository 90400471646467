<template>
  <div
    class="editor-items-menu"
    :class="[( horizontal ? 'editor-items-menu--horizontal' : '' ), ( vertical ? 'editor-items-menu--vertical' : '' )]"
  >
    <button
      v-for="block in blocksModelFilter(currentSiteName)"
      :key="block.type"
      :data-type="block.type"
      class="editor-items-menu__btn"
      :class="[
        ( horizontal ? 'editor-items-menu--horizontal' : '' ),
        ( vertical ? 'editor-items-menu--vertical' : '' ),
        ( horizontal && horizontalMenuNowHovered === block.type ? 'editor-items-menu__btn-active' : '')
      ]"
      @click="clickHandler"
      :disabled="disabled(block)"
    >
      <span
        v-if="block.type === 'themeBlock'"
        class="editor-items-menu__btn-icon fas fa-palette"
      />
      <span
        v-if="block.type === 'seriesBlock'"
        class="editor-items-menu__btn-icon fas fa-film"
      />
      <span
        v-if="block.type === 'specialArticleGroupBlock'"
        class="editor-items-menu__btn-icon fas fa-star"
      />
      <span
        v-if="block.type === 'tableBlock'"
        class="editor-items-menu__btn-icon fas fa-table"
      />
      <span
        v-if="block.type === 'videoBlock'"
        class="editor-items-menu__btn-icon fas fa-video"
      />
      <span
        v-if="block.type === 'carTableBlock'"
        class="editor-items-menu__btn-icon fa fa-car"
      />
      <span
        v-if="block.type === 'onlineSportMatchBlock'"
        class="editor-items-menu__btn-icon editor-items-menu__btn-icon-css"
      />
      <SvgIconVideo
        v-if="block.type === 'youtubeBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconImage
        v-if="block.type === 'imageBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconSoccer
        v-if="block.type === 'sportTableBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconInfoBox
        v-if="block.type === 'infoBoxBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconPoll
        v-if="block.type === 'pollBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconPageBreak
        v-if="block.type === 'pageBreakBlock'"
        class="editor-items-menu__btn-icon"
      />
      <span
        v-if="block.type === 'sourceBlock'"
        class="editor-items-menu__source-block-icon"
      >
        {{ `„“` }}
      </span>
      <span
        v-if="block.type === 'paidContentBreakBlock'"
        class="editor-items-menu__btn-icon"
      >
        €
      </span>
      <SvgIconText
        v-if="block.type === 'paragraphBlock'"
        class="editor-items-menu__btn-icon"
      />
      <span
        v-if="block.type === 'quizBlock'"
        class="editor-items-menu__btn-icon"
      >
        Q
      </span>
      <SvgIconQuote
        v-if="block.type === 'quoteBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconCode
        v-if="block.type === 'codeBlock'"
        class="editor-items-menu__btn-icon"
      />
      <span
        v-if="block.type === 'infographicBlock'"
        class="editor-items-menu__btn-icon fa fa-chart-area"
      />
      <span
        v-if="block.type === 'thirdPartyEmbedBlock'"
        class="editor-items-menu__btn-icon"
      >
        S
      </span>
      <SvgIconCircleStroke
        v-if="block.type === 'embeddedApplicationBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconHeading
        v-if="block.type === 'headingBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconUnorderedList
        v-if="block.type === 'unorderedListBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconOrderedList
        v-if="block.type === 'orderedListBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconLinkToGallery
        v-if="block.type === 'linkToGalleryBlock'"
        class="editor-items-menu__btn-icon"
      />
      <SvgIconRelatedArticle
        v-if="block.type === 'relatedArticleBlock'"
        class="editor-items-menu__btn-icon"
      />
      <EditorTooltip v-if="horizontal" class="editor-items-menu__tooltip">
        {{ $t(`nmhEditor.blocks.${block.type}.title`) }}
      </EditorTooltip>
      <span v-if="vertical" class="editor-items-menu__btn-text">
        {{ $t(`nmhEditor.blocks.${block.type}.title`) }}
      </span>
    </button>
    <div v-if="horizontal" class="editor-items-menu__extra-functionality">
      <button
        class="editor-items-menu__btn editor-items-menu__btn--copy"
        @click="openCopyPasteModal"
      >
        <SvgIconPaste class="editor-items-menu__btn-icon editor-items-menu__btn-icon--copy" />
        <EditorTooltip class="editor-items-menu__tooltip">
          {{ $t('nmhEditor.tooltips.copyPaste') }}
        </EditorTooltip>
      </button>
      <button
        class="editor-items-menu__btn editor-items-menu__btn--delete-all"
        :disabled="!blocks.length"
        @click="openDeleteAllModal"
      >
        <SvgIconBasket class="editor-items-menu__btn-icon editor-items-menu__btn-icon--delete-all" />
        <EditorTooltip class="editor-items-menu__tooltip">
          {{ $t('nmhEditor.tooltips.deleteBlocks') }}
        </EditorTooltip>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SvgIconCode from '@/assets/img/svg/code.svg?inline'
import SvgIconSoccer from '@/assets/img/svg/soccer.svg?inline'
import SvgIconVideo from '@/assets/img/svg/video.svg?inline'
import SvgIconImage from '@/assets/img/svg/image.svg?inline'
import SvgIconInfoBox from '@/assets/img/svg/infobox.svg?inline'
import SvgIconPoll from '@/assets/img/svg/poll.svg?inline'
import SvgIconPageBreak from '@/assets/img/svg/page-break.svg?inline'
import SvgIconHeading from '@/assets/img/svg/heading.svg?inline'
import SvgIconQuote from '@/assets/img/svg/quote.svg?inline'
import SvgIconText from '@/assets/img/svg/text.svg?inline'
import SvgIconUnorderedList from '@/assets/img/svg/unordered-list.svg?inline'
import SvgIconOrderedList from '@/assets/img/svg/ordered-list.svg?inline'
import SvgIconRelatedArticle from '@/assets/img/svg/related-article.svg?inline'
import SvgIconLinkToGallery from '@/assets/img/svg/link-to-gallery.svg?inline'
import SvgIconPaste from '@/assets/img/svg/paste.svg?inline'
import SvgIconBasket from '@/assets/img/svg/basket.svg?inline'
import SvgIconCircleStroke from '@/assets/img/svg/circle-stroke.svg?inline'
import EditorTooltip from '@/components/nmhEditor/EditorTooltip'
export default {
  name: 'EditorItemsMenu',
  components: {
    EditorTooltip,
    SvgIconSoccer,
    SvgIconVideo,
    SvgIconImage,
    SvgIconInfoBox,
    SvgIconPoll,
    SvgIconPageBreak,
    SvgIconCode,
    SvgIconHeading,
    SvgIconQuote,
    SvgIconText,
    SvgIconUnorderedList,
    SvgIconOrderedList,
    SvgIconRelatedArticle,
    SvgIconLinkToGallery,
    SvgIconPaste,
    SvgIconBasket,
    SvgIconCircleStroke
  },
  props: {
    clickHandler: {
      type: Function,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      blocks: state => state.nmhEditorStore.data.blocks,
      replaceableBlocks: state => state.nmhEditorStore.replaceableBlocks,
      hiddenBlocks: state => state.nmhEditorStore.companySpecificSettings.hiddenBlocks,
      currentSiteName: state => state.article.currentSiteName,
      selectBlockModalReplaceMode: state => state.nmhEditorStore.modals.selectBlock.replaceMode,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock,
      horizontalMenuNowHovered: state => state.nmhEditorStore.itemsMenu.horizontal.nowHovered
    }),
    ...mapGetters(['vlm'])
  },
  methods: {
    blocksModelFilter (site) {
      let excludedBlocks
      if (this.vlm) {
        excludedBlocks = this.hiddenBlocks.vlm
      } else {
        if (site === 'autobild') {
          excludedBlocks = ['paidContentBreakBlock']
        } else if (site === 'sport24') {
          excludedBlocks = ['carTableBlock', 'paidContentBreakBlock']
        } else if (['trend', 'reality', 'enjoy', 'medialne'].includes(site)) {
          excludedBlocks = ['carTableBlock']
        } else {
          excludedBlocks = ['carTableBlock', 'paidContentBreakBlock']
        }
        excludedBlocks.push(...this.hiddenBlocks.nmh)
      }

      return this.blocksModel.blocks.filter(item => !excludedBlocks.includes(item.type))
    },
    disabled (block) {
      return this.selectBlockModalReplaceMode &&
          (this.lastClickedBlock.type === block.type || !this.replaceableBlocks.includes(block.type))
    },
    openCopyPasteModal () {
      this.$store.commit('nmhEditorStore/SET_MODAL_COPY_PASTE', true)
    },
    openDeleteAllModal () {
      this.$store.commit('nmhEditorStore/SET_MODAL_DELETE_BLOCK', {
        isVisible: true,
        deleteAllBlocks: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .editor-items-menu {
    &--horizontal {
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0 0 20px 0 #fff;
      width: -moz-fit-content;
      width: fit-content;
    }
    &--vertical {
      @include padding(25px 30px);
      background: #f2f3f7;
      .editor-items-menu__source-block-icon {
        margin-right: rem(-8px);
      }
    }
    &__extra-functionality {
      display: grid;
      grid-template-columns: repeat(2, min-content);
    }
    &__btn {
      .editor-items-menu--horizontal & {
        @include margin(_ 2px 2px _);
        @include size(35px);
        padding: 0;
        cursor: pointer;
        border: 1px solid #d1dbe4;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: relative;
        transition: all 100ms;
        &:hover {
          background: #f5f5f5;
          .editor-items-menu__tooltip {
            opacity: 1;
            // transition-delay: 1s;
            z-index: 9;
          }
        }
      }
      .editor-items-menu--vertical & {
        @include padding(12px 0);
        @include size(100% _);
        display: flex;
        align-items: center;
        background: #f2f3f7;
        border: none;
        cursor: pointer;
        text-align: left;
        &:disabled {
          opacity: .4;
          cursor: not-allowed;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d1dbe4;
        }
        &:first-child {
          @include padding(0 _ _);
        }
        &:last-child {
          @include padding(_ _ 0);
        }
        &:hover {
          .editor-items-menu__btn-text {
            color: #000;
          }
        }
      }
      &--copy,
      &--delete-all {
        .editor-items-menu--horizontal & {
          background: #6599fe;
          border: 1px solid #6599fe;
          margin-right: 2px;
          margin-bottom: 2px;
          &:disabled {
            cursor: not-allowed;
            opacity: .6;
          }
          &:hover {
            background: #6599fe;
            .editor-items-menu__btn-icon--copy,
            .editor-items-menu__btn-icon--delete-all {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &__btn-active {
      .editor-items-menu--horizontal & {
        transform: scale(1.25);
        z-index: 9999;
      }
    }
    &__btn-icon {
      @include size(16px auto);
      fill: #6599fe;
      color: #6599fe;
      pointer-events: none;
      transform: scale(1);
      transition: all 100ms;
      .editor-items-menu--vertical & {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--copy,
      &--delete-all {
        fill: #fff;
      }
    }
    &__btn-icon-css {
      @include size(16px);
      @include radius(50%);
      background: #6599fe;
    }
    &__btn-text {
      .editor-items-menu--vertical & {
        @include font(400 16px "Roboto");
        @include margin(_ _ _ 18px);
        color: #465674;
        pointer-events: none;
        white-space: nowrap;
      }
    }
    &__tooltip {
      opacity: 0;
      pointer-events: none;
      transition: 0s all;
      z-index: -5;
    }
    &__source-block-icon {
      font-family: Georgia, serif;
      font-size: 200%;
      margin-top: rem(-8px);
      color: #6599fe;
      pointer-events: none;
    }
  }
</style>

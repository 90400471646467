<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="hasEmbeddedApplicationId"
    :click-handler-for-edit-btn="openModal"
  >
    <ButtonMain v-if="!hasEmbeddedApplicationId" :click-handler="openModal">
      {{ $t('nmhEditor.blocks.embeddedApplicationBlock.empty') }}
    </ButtonMain>

    <div v-if="hasEmbeddedApplicationId && embeddedApplicationData" class="embedded-application-block">
      <SvgIconCircleStroke class="embedded-application-block__icon"/>
      <span class="embedded-application-block__text">{{ blockTitle }}</span>
    </div>

    <EmbeddedApplicationBlockModal
      v-if="showModal"
      :embedded-application-id="properties.embeddedApplicationId"
      :field-values="properties.fieldValues"
      @close="closeModal"
      @set-data="setData"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import ButtonMain from '@/components/nmhEditor/btns/BtnMain'
import EmbeddedApplicationBlockModal from '@/components/article/richtext/EmbeddedApplicationBlockModal.vue'
import SvgIconCircleStroke from '@/assets/img/svg/circle-stroke.svg?inline'

export default {
  name: 'EmbeddedApplicationBlock',
  components: {
    EmbeddedApplicationBlockModal,
    Block,
    ButtonMain,
    SvgIconCircleStroke
  },
  props: {
    blockType: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    properties: {
      type: Object,
      required: true,
      default: () => ({ embeddedApplicationId: null, fieldValues: [] })
    }
  },
  data () {
    return {
      embeddedApplicationId: '',
      embeddedApplicationData: null,
      showModal: false
    }
  },
  computed: {
    hasEmbeddedApplicationId () {
      return !!this.properties.embeddedApplicationId
    },
    blockTitle () {
      const keyValuePair = this.embeddedApplicationData.fields
        .map(item => {
          const found = this.properties.fieldValues.find(property => property.identifier === item.identifier)
          return found ? `${item.displayName}: ${found.value}` : null
        })
        .filter(Boolean)
      return [this.embeddedApplicationData.displayName, ...keyValuePair].join(' - ')
    }
  },
  mounted () {
    this.loadEmbeddedApplication(this.properties.embeddedApplicationId)
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    },
    setData (data) {
      this.embeddedApplicationId = data.embeddedApplicationId
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'embeddedApplicationId',
        data: data.embeddedApplicationId
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'fieldValues',
        data: data.fieldValues
      })
      this.loadEmbeddedApplication(this.embeddedApplicationId)
    },
    async loadEmbeddedApplication (id) {
      if (!id) {
        return
      }
      this.embeddedApplicationData = await this.$store.dispatch('embeddedApplication/fetchOne', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.embedded-application-block {
  display: flex;
  align-items: center;
  gap: rem(8px);

  &__icon {
    fill: #6599fe;
  }

  &__text {
    @include font(700 15px "Roboto");
  }
}
</style>

<template>
  <Modal
      :title="$t('nmhEditor.blocks.embeddedApplicationBlock.title')"
      class="embedded-application-block-modal"
      @close="close"
  >
    <template v-slot:body>
      <Select v-model="item.embeddedApplicationId" :options="list" @input="handleInput"/>

      <div v-if="embeddedApplication && embeddedApplication.fields && embeddedApplication.fields.length" class="row">
        <div v-for="(field, index) in embeddedApplication.fields" :key="embeddedApplication.id + index" class="col-lg-6">
          <Input
              v-if="[ 'string', 'number' ].includes(field.type)"
              :type="inputType(field.type)"
              v-model="item.fieldValues[index].value"
              :label="field.displayName"
              :required="field.isRequired"
              @blur="$v.item.fieldValues.$each[index].$touch()"
              :error="$v.item.fieldValues.$each[index].$error"
          />

          <SwitchCheckbox
              v-else
              v-model="item.fieldValues[index].value"
              :label="field.displayName"
              :required="field.isRequired"
              class="mb-3 embedded-application-block-modal__text"
          />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button class="btn btn-default" @click="close">{{ $t('buttons.close') }}</button>
      <button class="btn btn-success" @click="insert">{{ $t('buttons.save') }}</button>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/shared/Modal'
import Select from '@/components/form/select/Select.vue'
import Input from '@/components/form/inputs/Input.vue'
import SwitchCheckbox from '@/components/form/SwitchCheckbox.vue'
import NotifyService from '@/services/NotifyService'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'EmbeddedApplicationBlockModal',
  components: {
    SwitchCheckbox,
    Input,
    Select,
    Modal
  },
  data () {
    return {
      isLoading: true,
      embeddedApplication: null,
      item: {
        embeddedApplicationId: this.embeddedApplicationId,
        fieldValues: this.fieldValues
      },
      fields: this.fieldValues
    }
  },
  props: {
    embeddedApplicationId: {
      type: Number,
      required: false,
      default: null
    },
    fieldValues: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    list () {
      return this.$store.getters['embeddedApplication/list'].map(item => ({ id: item.id, title: item.displayName }))
    },
    composeProperties () {
      return {
        embeddedApplicationId: this.item.embeddedApplicationId,
        fieldValues: this.item.fieldValues
      }
    }
  },
  async mounted () {
    await this.fetchEmbeddedApplications()
    if (this.embeddedApplicationId) {
      await this.fetchEmbeddedApplication()
    }
  },
  methods: {
    async fetchEmbeddedApplications () {
      await this.$store.dispatch('embeddedApplication/fetch', { isActive: 1 })
      this.isLoading = false
    },
    async fetchEmbeddedApplication (value = null) {
      const result = await this.$store.dispatch('embeddedApplication/fetchOne', this.item.embeddedApplicationId)
      // Fields in bodyBlock can be in different order than in embeddedApplication
      const sortedFields = result.fields.sort((a, b) => {
        return this.fieldValues.findIndex(field => field.identifier === a.identifier) - this.fieldValues.findIndex(field => field.identifier === b.identifier)
      })
      this.embeddedApplication = { ...result, fields: sortedFields }

      if (value && value !== this.embeddedApplicationId) {
        this.item.fieldValues = this.embeddedApplication.fields.map(field => {
          return {
            identifier: field.identifier,
            type: field.type,
            value: field.type === 'boolean' ? false : null
          }
        })
      }
    },
    close () {
      this.$emit('close')
    },
    insert () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$emit('set-data', this.composeProperties)
      this.close()
    },
    async handleInput (value) {
      if (!value) {
        return
      }
      await this.fetchEmbeddedApplication(value)
    },
    inputType (type) {
      return type === 'number' ? 'number' : 'text'
    }
  },
  validations () {
    return {
      item: {
        fieldValues: {
          $each: {
            value: {
              required: requiredIf((value) => {
                return this.embeddedApplication.fields.find(field => field.identifier === value.identifier).isRequired
              })
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.embedded-application-block-modal {
  &__text {
    @include font(400 14px "Roboto");
    color: #8A96AC;
  }
}
</style>

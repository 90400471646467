<template>
  <AbstractFilter
    :on-filter="filterPolls"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="poll_new"
    filter-panel-class="filter-panel-poll-vlm"
  >
    <SiteSelect
      v-model="filter.site"
      :options="sites"
      :label="$t('pollVlm.filter.server')"
      id="filter_site"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('pollVlm.filter.title')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('pollVlm.filter.id')"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import PollFilterModel from '@/model/PollFilterVlm'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'PollFilterVlm',
  components: {
    SiteSelect,
    AbstractFilter,
    Input
  },
  data () {
    return {
      filter: this._.cloneDeep(PollFilterModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.POLL_PERMISSIONS
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/all']
    }
  },
  methods: {
    filterPolls () {
      this.$store.commit('pollVlm/setPage', 1)
      this.$store.commit('pollVlm/setFilter', this.filter)
      this.$store.dispatch('pollVlm/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(PollFilterModel)
      this.$store.commit('pollVlm/setFilter', this.filter)
      this.$store.dispatch('pollVlm/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['pollVlm/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-poll-vlm {
  @include bp(7) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>

<template>
  <div class="modal-link">
    <form
      v-click-outside="modalHandler"
      class="modal-link__dialog-wr"
      @submit="setLink"
    >
      <h4 class="modal-link__title">{{ $t('nmhEditor.modals.link.title') }}</h4>
      <div class="modal-link__content-area">
        <div>
          <label class="modal-link__label" for="modal-link__title">
            <span class="modal-link__desc">{{ $t('nmhEditor.modals.link.titleLabel') }}: </span>
            <input
              id="modal-link__title"
              class="modal-link__input"
              type="text"
              v-model="title"
            >
          </label>
          <div class="modal-link__input__error-message">
            &nbsp;
            {{ title ? '' : $t('nmhEditor.modals.link.invalidTitle') }}
          </div>
          <label class="modal-link__label" for="modal-link__input">
            <span class="modal-link__desc">{{ $t('nmhEditor.modals.link.urlLabel') }}: </span>
            <input
              id="modal-link__input"
              ref="modalLinkInput"
              class="modal-link__input"
              type="text"
              :value="inputUrl"
              @input="onUrlInput"
              placeholder="https://www..."
            >
          </label>
          <div class="modal-link__input__error-message">
            &nbsp;
            {{ isLinkValid ? '' : $t('nmhEditor.modals.link.invalidUrl') }}
          </div>
          <label class="modal-link__label modal-link__label--body-block">
            <span class="modal-link__desc">{{ $t('nmhEditor.modals.link.bodyBlockLabel') }}: </span>
            <Select
              :value="bodyBlock"
              @input="onBodyBlockSelected"
              :option-value="null"
              :options="bodyBlocks"
              :custom-title="getBodyBlockHtmlId"
              id="modal-link__body-block-select"
              no-label
              disable-form-group
            />
          </label>
        </div>
        <label class="modal-link__label">
          <span class="modal-link__desc">{{ $t('nmhEditor.modals.link.targetLabel') }}: </span>
          <select class="modal-link__select" v-model="selectOption">
            <option value="newWindow" class="modal-link__option">{{ $t('nmhEditor.modals.link.newWindow') }}</option>
            <option value="currentWindow" class="modal-link__option">{{ $t('nmhEditor.modals.link.currentWindow') }}</option>
          </select>
        </label>
        <label class="modal-link__label">
          <span class="modal-link__desc">{{ $t('nmhEditor.modals.link.relLabel') }}: </span>
          <select class="modal-link__select" v-model="rel">
            <option :value="relOptions.noopener" class="modal-link__option">{{ $t('nmhEditor.modals.link.notFilled') }}</option>
            <option :value="relOptions.nofollow" class="modal-link__option">{{ $t('nmhEditor.modals.link.nofollow') }}</option>
          </select>
        </label>
      </div>
      <div class="modal-link__btn-area">
        <button
          :class="`modal-link__btn modal-link__btn--primary${error ? '-disabled' : ''}`"
          :disabled="error"
          @click.prevent="setLink" type="button"
        >
          {{ $t('nmhEditor.modals.link.btn.confirm') }}
        </button>
        <button
          class="modal-link__btn modal-link__btn--secondary"
          @click.prevent="modalHandler" type="button"
        >
          {{ $t('nmhEditor.modals.link.btn.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isURL } from '@/filters'
import Select from '@/components/form/select/Select'

export default {
  name: 'ModalLink',
  props: {
    editor: {
      type: null,
      required: true
    }
  },
  components: {
    Select
  },
  data () {
    return {
      title: '',
      inputUrl: '',
      bodyBlock: null,
      checkBoxBlankUrl: false,
      selectOption: 'newWindow', // newWindow || currentWindow
      rel: 'noopener',
      relOptions: {
        noopener: 'noopener',
        nofollow: 'noopener nofollow'
      },
      isInitiallyEmpty: false
    }
  },
  computed: {
    ...mapState({
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock
    }),
    error () {
      return !this.isLinkValid || !this.title
    },
    isLinkValid () {
      return !this.inputUrl ||
        (this.bodyBlock && this.getBodyBlockHtmlId(this.bodyBlock) === this.inputUrl) ||
        isURL(this.inputUrl)
    },
    bodyBlocks () {
      return this.$store.state.nmhEditorStore.data.blocks.filter(b => b.properties.htmlId)
    }
  },
  methods: {
    modalHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_LINK', false)
    },
    getBodyBlockHtmlId (block) {
      if (!block.properties.htmlId) {
        return ''
      }
      return `#${block.properties.htmlId}`
    },
    onBodyBlockSelected (block) {
      this.bodyBlock = block
      if (block) {
        this.inputUrl = this.getBodyBlockHtmlId(block)
        this.selectOption = 'currentWindow'
      } else {
        this.inputUrl = ''
      }
    },
    onUrlInput (event) {
      this.inputUrl = event.target.value
      this.bodyBlock = this.bodyBlocks.find(block => `#${block.properties.htmlId}` === this.inputUrl)
      if (this.inputUrl && this.bodyBlock) {
        this.selectOption = 'currentWindow'
      }
    },
    setLink () {
      if (!this.inputUrl) {
        this.editor.chain().focus().unsetLink().run()
        this.modalHandler()
        return
      }
      const windowTarget = this.selectOption === 'newWindow' ? '_blank' : '_self'
      this.selectLinkText()

      this.editor
        .chain()
        .focus()
        .setLink({ href: this.inputUrl, target: windowTarget, rel: this.rel })
        .command(({ tr }) => {
          this.isInitiallyEmpty && tr.insertText(this.title)
          return true
        })
        .run()
      this.modalHandler()
    },
    selectLinkText () {
      this.editor.chain().focus().extendMarkRange('link').run()
    },
    getSelectedText () {
      this.selectLinkText()
      const { view, state } = this.editor
      const { from, to } = view.state.selection
      return state.doc.textBetween(from, to, '')
    }
  },
  mounted () {
    this.title = this.getSelectedText()
    this.isInitiallyEmpty = !this.title
    setTimeout(() => {
      this.$refs.modalLinkInput.focus()
    }, 100)
    const linkAttributes = this.editor.getAttributes('link')
    const previousUrl = linkAttributes.href
    const previousTarget = linkAttributes.target
    if (previousTarget === '' || previousTarget === '_blank') {
      this.selectOption = 'newWindow'
    }
    if (previousTarget === '_self') {
      this.selectOption = 'currentWindow'
    }
    if (typeof previousUrl === 'string' && previousUrl.length > 0) {
      if (previousUrl[0] === '#') {
        this.bodyBlock = this.bodyBlocks.find(block => `#${block.properties.htmlId}` === previousUrl)
      }
      this.inputUrl = previousUrl
    }
    if (linkAttributes.rel?.includes('nofollow')) {
      this.rel = this.relOptions.nofollow
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-link {
    @include fixed(0, 100);
    @include size(100% 100vh);
    @include padding(_ 10px);
    overflow-y: scroll;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &__dialog-wr {
      @include size(500px _);
      @include padding(30px 50px);
      @include radius(6px);
      background: #f2f3f7;
      box-shadow: 0 0 30px 5px #8d8d8d;
    }
    &__title {
      @include font(400 18px "Roboto");
      @include margin(0);
      color: #465674;
    }
    &__content-area {
      @include margin(25px _ 15px);
      @include padding(20px _ _);
      @include grid-gap(8px);
      display: grid;
      border-top: 1px solid #ddd;
    }
    &__label {
      display: grid;
      grid-template-columns: 120px auto;
      align-items: center;
      margin-top: 0.4rem;
      &--body-block {
        margin-top: 0.2rem;
        margin-bottom: 0.4rem;
      }
    }
    &__desc {
      @include font(400 16px "Roboto");
      @include margin(0);
      color: #465674;
      text-align: right;
      padding-right: 0.4rem;
    }
    &__select,
    &__input {
      @include font(400 14px "Roboto");
      @include radius(4px);
      @include padding(5px 10px);
      border: 1px solid #d1dbe4;
      &::placeholder {
        opacity: .5;
      }
      &__error-message {
        @include font(400 13px "Roboto");
        padding-top: 0.2rem;
        color: #FF3455;
        text-align: right;
      }
    }
    &__btn-area {
      @include grid-gap(8px);
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: flex-end;
    }
    &__btn {
      @include font(400 16px "Roboto");
      @include radius(3px);
      @include padding(8px 15px);
      border: none;
      cursor: pointer;
      transition: 100ms all;
      &--primary {
        background: #6599fe;
        color: #fff;
        &:hover {
          background: darken(#6599fe, 10%);
        }
      }
      &--primary-disabled {
        background-color: #D1DBE4;
        cursor: default;
        &:hover {
          background-color: #D1DBE4;
        }
      }
      &--secondary {
        border: 1px solid #000;
        color: #000;
        &:hover {
          opacity: .6;
        }
      }
    }
  }
</style>
